<template>
  <div style="height:100%">
    <portal to="app-header">
      <v-btn class="mb-1" icon @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ $t('maintenancePlan.addPlan') }}</span>
    </portal>
    <v-container fill-height v-if="loading">
      <v-row
        align="center"
        justify="center"
        :no-gutters="$vuetify.breakpoint.smAndDown"
      >
        <v-col cols="12" align="center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="72"
          ></v-progress-circular>
        </v-col>
        <v-col cols="12" align="center">
          <div class="headline">
            {{ $t('maintenancePlan.loadingTitle') }}
          </div>
          <div class="title">
            {{ $t('maintenancePlan.fetchingPlan') }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <add-plan v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AddPlan from '../components/actions/AddPlan.vue';

export default {
  name: 'AddMaintenancePlan',
  components: {
    AddPlan,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('maintenancePlanning', ['machines', 'solutions', 'crons']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  async created() {
    const machinesNotAvailable = this.machines.length === 0;
    const solutionsNotAvailable = this.solutions.length === 0;
    const cronsNotAvailable = this.crons.length === 0;
    if (machinesNotAvailable || solutionsNotAvailable || cronsNotAvailable) {
      this.loading = true;
      await Promise.all([
        this.fetchMachines(),
        this.fetchSolutions(),
        this.fetchCrons(),
      ]);
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('maintenancePlanning', [
      'fetchMachines',
      'fetchSolutions',
      'fetchCrons',
    ]),
    goBack() {
      this.$router.push({ name: 'maintenancePlan' });
    },
  },
};
</script>
