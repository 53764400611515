<template>
  <a @click="goToPlan" class="text-decoration-underline">
    {{ planName }}
  </a>
</template>

<script>
export default {
  name: 'PlanIdTemplate',
  props: {
    planid: {
      type: String,
      required: true,
    },
    planName: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToPlan() {
      this.$router.push({
        name: 'maintenancePlanDetails',
        params: { id: this.planid },
      });
    },
  },
};
</script>
