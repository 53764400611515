<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        small
        icon
        class="text-none"
        color="primary"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <v-form ref="form" v-model="isValid" @submit.prevent="update">
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          {{ $t('maintenancePlan.editSparepart') }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :loading="loading"
            outlined
            class="mt-1"
            :label="$t('maintenancePlan.sparepartName')"
            :items="parts"
            :rules="[(v) => !!v || $t('maintenancePlan.required')]"
            :disabled="saving"
            item-text="sparepartname"
            item-value="sparepartid"
            v-model="item.sparepartid"
          >
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.sparepartname"></v-list-item-title>
                <v-list-item-subtitle v-text="item.sparepartcode"></v-list-item-subtitle>
                <v-list-item-subtitle v-text="item.machinepositionname"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field
            outlined
            :label="$t('maintenancePlan.upperLimit')"
            :disabled="saving"
            :rules="[(v) => (!!v || v === 0) || $t('maintenancePlan.required')]"
            v-model.number="item.upper"
          ></v-text-field>
          <v-text-field
            outlined
            :label="$t('maintenancePlan.lowerLimit')"
            :disabled="saving"
            :rules="[(v) => (!!v || v === 0) || $t('maintenancePlan.required')]"
            v-model.number="item.lower"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text :disabled="saving" class="text-none" @click="cancel">
            {{ $t('maintenancePlan.exit') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="primary"
            class="text-none"
            :loading="saving"
            :disabled="!isValid"
          >
            {{ $t('maintenancePlan.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'EditSparepart',
  props: {
    selectedPart: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: null,
      parts: [],
      isValid: false,
      dialog: false,
      loading: false,
      saving: false,
      id: null,
    };
  },
  created() {
    this.initItem();
  },
  computed: {
    ...mapState('maintenancePlanning', [
      'selectedPlan',
    ]),
  },
  methods: {
    ...mapActions('maintenancePlanning', [
      'fetchMachineSpareParts',
      'updateSparepartById',
    ]),
    initItem() {
      this.item = {
        sparepartid: '',
        lower: '',
        upper: '',
        assetid: 0,
      };
    },
    cancel() {
      this.initItem();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      this.dialog = false;
    },
    async update() {
      this.saving = true;
      const { planid, name } = this.selectedPlan[0];
      const sparePart = this.parts.find((p) => p.sparepartid === this.item.sparepartid);
      const {
        sparepartname,
        sparepartcode,
        warehousecode,
        warehousename,
        machineid,
        machinepositionid,
        machinepositionname,
      } = sparePart;
      this.item.planid = planid;
      this.item.planname = name;
      this.item.sparepartname = sparepartname;
      this.item.sparepartcode = sparepartcode;
      this.item.warehousecode = warehousecode;
      this.item.warehousename = warehousename;
      this.item.machineid = machineid;
      this.item.machinepositionid = machinepositionid;
      this.item.machinepositionname = machinepositionname;
      const updated = await this.updateSparepartById({
        id: this.id,
        payload: this.item,
      });
      if (updated) {
        this.cancel();
      }
      this.saving = false;
    },
  },
  watch: {
    async dialog(val) {
      if (val) {
        this.loading = true;
        this.parts = await this.fetchMachineSpareParts(this.selectedPlan[0].machineid);
        const {
          sparepartid,
          lower,
          upper,
          _id: id,
        } = this.selectedPart;
        this.id = id;
        this.item.sparepartid = sparepartid;
        this.item.lower = lower;
        this.item.upper = upper;
        this.loading = false;
      }
    },
  },
};
</script>
