<template>
  <validation-observer ref="form" #default="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(onSaveAndAddNew)">
      <v-container fluid>
        <v-row
          justify="center"
          :no-gutters="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" xl="9" class="text-justify">
            <v-card flat class="transparent">
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="planType"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-select
                        filled
                        :label="$t('maintenancePlan.planType')"
                        :items="typeList"
                        item-text="name"
                        item-value="value"
                        :error-messages="errors"
                        :disabled="saving"
                        v-model="plan.type"
                        @change="onTypeChange"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <validation-provider
                      name="planname"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        clearable
                        filled
                        :label="$t('maintenancePlan.planName')"
                        :error-messages="errors"
                        v-model="plan.name"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="machinename"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        :label="$t('maintenancePlan.machine')"
                        :items="machineList"
                        :error-messages="errors"
                        :disabled="saving"
                        item-text="name"
                        item-value="id"
                        v-model="plan.machineid"
                        @change="onMachineSelection"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="solutionname"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        :label="$t('maintenancePlan.solution')"
                        :items="solutionList"
                        :error-messages="errors"
                        :disabled="saving"
                        item-text="name"
                        item-value="id"
                        v-model="plan.solutionid"
                        @change="onSolutionSelection"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="maintenancecron"
                      :rules="`${isTBM
                        ? 'required'
                        : ''}`"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        v-if="isTBM"
                        :label="$t('maintenancePlan.cron')"
                        :items="cronList"
                        :error-messages="errors"
                        :disabled="saving"
                        item-text="name"
                        item-value="cron"
                        v-model="plan.cron"
                        @change="onCronSelection"
                      >
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="item.cron"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                    <validation-provider
                      name="maintenancecount"
                      :rules="`${isCBM
                        ? 'required|numeric|min_value:1'
                        : ''}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('maintenancePlan.count')"
                        type="number"
                        v-if="isCBM"
                        :disabled="saving"
                        :error-messages="errors"
                        filled
                        v-model.number="plan.duration"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="2">
                    <v-checkbox
                      hide-details
                      class="ma-0 pa-0"
                      :label="$t('maintenancePlan.active')"
                      :disabled="saving"
                      v-model="enabled"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="text-none"
                  :disabled="saving"
                  @click="exit"
                >
                  {{ $t('maintenancePlan.exit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  class="text-none"
                  :disabled="savingAndNew"
                  :loading="savingAndExit"
                  @click="onSaveAndExit"
                >
                  {{ $t('maintenancePlan.saveExit') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="text-none"
                  :disabled="savingAndExit"
                  :loading="savingAndNew"
                  type="submit"
                >
                  {{ $t('maintenancePlan.saveAddNew') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'AddPlan',
  data() {
    return {
      savingAndExit: false,
      savingAndNew: false,
      plan: null,
      enabled: true,
      typeList: [{
        name: this.$t('maintenancePlan.TBM'),
        value: 'TBM',
      }, {
        name: this.$t('maintenancePlan.CBM'),
        value: 'CBM',
      }],
    };
  },
  computed: {
    ...mapGetters('maintenancePlanning', [
      'machineList',
      'solutionList',
      'cronList',
    ]),
    ...mapGetters('user', [
      'fullName',
    ]),
    saving() {
      return this.savingAndExit || this.savingAndNew;
    },
    isCBM() {
      return this.plan.type === 'CBM';
    },
    isTBM() {
      return this.plan.type === 'TBM';
    },
  },
  created() {
    this.initPlan();
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('maintenancePlanning', [
      'createPlan',
    ]),
    initPlan() {
      this.plan = {
        assetid: 0,
        cron: '',
        cronname: '',
        machinecode: '',
        machineid: '',
        machinename: '',
        name: '',
        solutionid: '',
        solutionname: '',
        solutiontype: '',
        duration: 0,
        type: this.typeList[0].value,
        unit: '',
      };
      this.enabled = true;
    },
    clear() {
      this.initPlan();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    onTypeChange() {
      this.plan.cron = '';
      this.plan.cronname = '';
      this.plan.duration = 0;
    },
    onMachineSelection(machineId) {
      if (machineId) {
        const machine = this.machineList.find((m) => m.id === machineId);
        const { name, machinecode, assetid } = machine;
        this.plan.machinename = name;
        this.plan.machinecode = machinecode;
        this.plan.assetid = assetid;
      } else {
        this.plan.machinename = '';
        this.plan.machinecode = '';
        this.plan.assetid = 0;
      }
    },
    onSolutionSelection(solutionId) {
      if (solutionId) {
        const solution = this.solutionList.find((m) => m.id === solutionId);
        const { name, type } = solution;
        this.plan.solutionname = name;
        this.plan.solutiontype = type;
      } else {
        this.plan.solutionname = '';
        this.plan.solutiontype = '';
      }
    },
    onCronSelection(cronExp) {
      if (cronExp) {
        const selectedCron = this.cronList.find((m) => m.cron === cronExp);
        const { name, cron } = selectedCron;
        this.plan.cronname = name;
        this.plan.cron = cron;
      } else {
        this.plan.cronname = '';
        this.plan.cron = '';
      }
    },
    exit() {
      this.clear();
      this.$router.push({ name: 'maintenancePlan' });
    },
    async save() {
      if (this.isCBM) {
        this.plan.unit = 'count';
      } else if (this.isTBM) {
        this.plan.unit = 'day';
      }
      this.plan.status = this.enabled ? 'enable' : 'unable';
      const payload = {
        ...this.plan,
        createdtime: new Date().getTime(),
        createdby: this.fullName,
        starttrigger: this.enabled
          ? formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
          : null,
      };
      const created = await this.createPlan(payload);
      if (created) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'PLAN_CREATED',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ERROR_CREATING_PLAN',
        });
      }
      return created;
    },
    async onSaveAndAddNew() {
      this.savingAndNew = true;
      const created = await this.save();
      if (created) {
        this.clear();
      }
      this.savingAndNew = false;
    },
    async onSaveAndExit() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.savingAndExit = true;
        const created = await this.save();
        if (created) {
          this.exit();
        }
        this.savingAndExit = false;
      }
    },
  },
};
</script>
