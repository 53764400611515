<template>
  <span class="ml-4">
    <toggle-plan
      :listType="listType"
      :enabled="enabled"
      :planId="planId"
      small
    />
    <!-- <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-on="on"
          v-bind="attrs"
          small
          class="ml-2"
          @click="$router.push({
            name: 'editMaintenancePlan',
            params: { id: planId },
          })"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
      {{ $t('maintenancePlan.editPlan') }}
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-on="on"
          v-bind="attrs"
          small
          class="mx-2"
          @click="$router.push({
            name: 'duplicateMaintenancePlan',
            params: { id: planId },
          })"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      {{ $t('maintenancePlan.duplicatePlan') }}
    </v-tooltip> -->
    <delete-plan
      :listType="listType"
      :planId="planId"
      small
    />
  </span>
</template>

<script>
import TogglePlan from '../actions/TogglePlan.vue';
import DeletePlan from '../actions/DeletePlan.vue';

export default {
  name: 'DetailsToolbar',
  props: {
    listType: {
      type: String,
      default: '',
    },
    plan: {
      type: Object,
      required: true,
    },
  },
  components: {
    TogglePlan,
    DeletePlan,
  },
  computed: {
    planId() {
      return this.plan.planid;
    },
    enabled() {
      return this.plan.status === 'enable';
    },
  },
};
</script>
