<template>
  <v-card
    outlined
    rounded="lg"
  >
    <v-card-title>
      <span class="title">
        {{ $t('maintenancePlan.recentTasks') }}
      </span>
      &nbsp;&nbsp;
      <span class="caption" v-if="size">
        ({{ $t('maintenancePlan.taskCount', { size, total: totalCount }) }})
      </span>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        hide-default-footer
        disable-pagination
        item-key="_id"
        :loading="loading"
      >
        <!-- eslint-disable-next-line -->
        <template #item.status="{ item }">
          <v-chip
            label
            :color="getTaskStatus(item.status).color"
          >
            {{ $t(`maintenancePlan.${getTaskStatus(item.status).text}`) }}
          </v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.operators="{ item }">
          <div v-for="(op, n) in item.operators" :key="n">
            {{ op.operatorname }}
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.tasktrigger="{ item }">
          {{ getFormattedDate(item.tasktrigger) }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.acturalstarttime="{ item }">
          {{ getFormattedDate(item.acturalstarttime) }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.acturalendtime="{ item }">
          {{ getFormattedDate(item.acturalendtime) }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.action="{ item }">
          <a @click="goToTask(item.id)" class="text-decoration-underline">
            {{ $t(`maintenancePlan.moreDetails`) }}
          </a>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'TaskList',
  inject: ['theme'],
  data() {
    return {
      loading: false,
      headers: [
        { text: this.$t('maintenancePlan.status'), value: 'status' },
        { text: this.$t('maintenancePlan.lastTrigger'), value: 'tasktrigger' },
        { text: this.$t('maintenancePlan.operators'), value: 'operators' },
        { text: this.$t('maintenancePlan.taskStartTime'), value: 'acturalstarttime' },
        { text: this.$t('maintenancePlan.taskEndTime'), value: 'acturalendtime' },
        { text: '', value: 'action', sortable: false },
      ],
      items: [],
      size: 0,
      totalCount: 0,
    };
  },
  async created() {
    this.loading = true;
    const data = await this.fetchRecentTasks(this.plan.planid);
    if (data && data.results) {
      const taskData = await Promise.all(data.results.map(async (t) => {
        const taskOperators = await this.fetchTaskOperators(t.id);
        return {
          ...t,
          operators: taskOperators.map(({ operatorid }) => {
            const operator = this.operators.find((o) => o.id === operatorid);
            return operator;
          }),
        };
      }));
      this.items = taskData;
      this.totalCount = data.totalCount;
      this.size = data.results.length;
    }
    this.loading = false;
  },
  computed: {
    ...mapState('maintenancePlanning', ['selectedPlan', 'operators']),
    ...mapGetters('maintenancePlanning', ['taskStatus']),
    ...mapGetters('helper', ['locale']),
    plan() {
      return this.selectedPlan[0];
    },
  },
  methods: {
    ...mapActions('maintenancePlanning', ['fetchRecentTasks', 'fetchTaskOperators']),
    getTaskStatus(status) {
      return this.taskStatus(status);
    },
    getFormattedDate(datetime) {
      if (datetime) {
        return formatDate(
          new Date(datetime),
          'MMM d, HH:mm',
          { locale: this.locale },
        );
      }
      return '-';
    },
    goToTask(taskId) {
      this.$router.push({
        path: `/maintenance-task/task/${taskId}`,
      });
    },
  },
};
</script>
