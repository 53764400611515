<template>
  <v-card
    flat
    :class="isDark ? 'secondary' : 'primary'"
    dark
    rounded="lg"
  >
    <v-card-title primary-title>
      {{ planName }}
      <v-spacer></v-spacer>
      <v-chip
        label
        :color="getPlanStatus(status).color"
      >
        {{ $t(`maintenancePlan.${getPlanStatus(status).text}`) }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenancePlan.machine') }}
          </div>
          <div class="title white--text">
            {{ machine }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenancePlan.type') }}
          </div>
          <div class="title warning--text">
            {{ type }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenancePlan.createdAt') }}
          </div>
          <div class="title white--text">
            {{ createdAt }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenancePlan.createdBy') }}
          </div>
          <div class="title white--text">
            {{ createdBy }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenancePlan.lastTriggered') }}
          </div>
          <div class="title white--text">
            {{ lastTriggered }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenancePlan.checklist') }}
          </div>
          <div class="title white--text">
            {{ checklist }}
          </div>
        </v-col>
        <v-col cols="8" v-if="isTBM">
          <div>
            {{ $t('maintenancePlan.cron') }}
          </div>
          <div class="white--text">
            <span class="title">{{ cronName }}</span>
            &nbsp;&nbsp;
            <span class="caption">[{{ cron }}]</span>
          </div>
        </v-col>
        <v-col cols="8" v-else-if="isCBM">
          <div>
            {{ $t('maintenancePlan.count') }}
          </div>
          <div class="title white--text">
            {{ count }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2" v-if="adherenceInfo">
        <v-col cols="12">
          <div>
            {{ $t('maintenancePlan.adherence') }}
          </div>
          <div class="font-weight-medium white--text">
            <v-progress-linear
              :height="25"
              :color="isDark ? 'primary' : 'secondary'"
              :value="adherenceInfo.adherence"
            >
              {{ adherenceInfo.completed }}/{{adherenceInfo.total}}
            </v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'PlanInfo',
  inject: ['theme'],
  data() {
    return {
      adherenceInfo: null,
    };
  },
  async created() {
    this.adherenceInfo = await this.fetchPlanAdherence(this.plan.planid);
  },
  computed: {
    ...mapState('maintenancePlanning', ['selectedPlan']),
    ...mapGetters('maintenancePlanning', ['planStatus']),
    ...mapGetters('helper', ['locale']),
    plan() {
      return this.selectedPlan[0];
    },
    isTBM() {
      return this.plan.type === 'TBM';
    },
    isCBM() {
      return this.plan.type === 'CBM';
    },
    isDark() {
      return this.theme.isDark;
    },
    status() {
      return this.plan.status;
    },
    planName() {
      return this.plan.name;
    },
    machine() {
      return this.plan.machinename;
    },
    type() {
      return this.plan.type;
    },
    checklist() {
      return this.plan.solutionname;
    },
    count() {
      return this.plan.duration;
    },
    cronName() {
      return this.plan.cronname;
    },
    cron() {
      return this.plan.cron;
    },
    createdAt() {
      return this.getFormattedDate(this.plan.createdtime);
    },
    createdBy() {
      return this.plan.createdby;
    },
    lastTriggered() {
      return this.plan.lasttrigger
        ? this.getFormattedDate(this.plan.lasttrigger)
        : '-';
    },
  },
  methods: {
    ...mapActions('maintenancePlanning', ['fetchPlanAdherence']),
    getPlanStatus(status) {
      return this.planStatus(status);
    },
    getFormattedDate(datetime) {
      return formatDate(
        new Date(datetime),
        'MMM d, HH:mm',
        { locale: this.locale },
      );
    },
  },
};
</script>
