<template>
  <v-select
    dense
    outlined
    multiple
    return-object
    item-text="name"
    v-model="status"
    :items="statusList"
    :label="$t('maintenancePlan.status')"
    prepend-inner-icon="mdi-list-status"
  >
    <template #selection="{ item, attrs, selected }">
      <v-chip
        :key="JSON.stringify(item)"
        v-bind="attrs"
        small
        :input-value="selected"
        :color="planStatus(item.value).color"
      >
        {{ item.name }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import {
  mapMutations,
  mapGetters,
} from 'vuex';

const FIELD_NAME = 'status';

export default {
  name: 'StatusSelection',
  data() {
    return {
      statusList: [{
        name: this.$t('maintenancePlan.active'),
        value: 'enable',
      }, {
        name: this.$t('maintenancePlan.inactive'),
        value: 'unable',
      }],
    };
  },
  computed: {
    ...mapGetters('webApp', ['filters']),
    ...mapGetters('maintenancePlanning', ['planStatus']),
    isStatusFilterInactive() {
      return !Object
        .keys(this.filters)
        .includes(FIELD_NAME);
    },
    status: {
      get() {
        const statusFilter = this.filters && this.filters[FIELD_NAME];
        if (statusFilter) {
          const value = this.statusList.filter((s) => statusFilter.value.includes(s.value));
          if (value && value.length) {
            return value;
          }
        }
        return this.statusList;
      },
      set(statusVal) {
        this.setStatusFilter(statusVal);
      },
    },
  },
  created() {
    if (this.isStatusFilterInactive) {
      this.setStatusFilter(this.statusList);
    }
  },
  methods: {
    ...mapMutations('webApp', ['setFilter']),
    setStatusFilter(status) {
      this.setFilter({
        field: FIELD_NAME,
        value: {
          value: status.map((s) => s.value),
          operation: 'includes',
        },
      });
    },
  },
};
</script>
