<template>
  <portal to="app-extension">
    <v-toolbar
      flat
      dense
      :color="$vuetify.theme.dark ? '#121212': ''"
    >
      <v-spacer></v-spacer>
      <v-btn
        small
        class="text-none mr-2"
        color="primary"
        :to="{ name: 'addMaintenancePlan' }"
      >
        <v-icon left small>mdi-plus</v-icon>
        {{ $t('maintenancePlan.addPlan') }}
      </v-btn>
      <v-badge
        overlap
        bordered
        color="primary"
        icon="mdi-check-outline"
        :value="showBadge"
      >
        <v-btn
          small
          outlined
          color="primary"
          class="text-none"
          @click="toggleDrawer(true)"
        >
          <v-icon small left>mdi-filter-variant</v-icon>
          {{ $t('maintenancePlan.filters') }}
        </v-btn>
      </v-badge>
    </v-toolbar>
  </portal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'PlanningToolbar',
  computed: {
    ...mapState('maintenancePlanning', [
      'drawer',
      'view',
      'today',
      'calendarRef',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ...mapGetters('webApp', ['filters']),
    ...mapGetters('helper', ['locale']),
    ...mapGetters('maintenancePlanning', ['isCalendarView']),
    viewType: {
      get() {
        return this.view;
      },
      set(view) {
        this.setView(view);
      },
    },
    showBadge() {
      const isMachineFilter = this.filters.machinename
        && !this.filters.machinename.value.includes('All');
      const isTypeFilter = this.filters.type
        && !this.filters.type.value.includes('All');
      const isStatusFilter = this.filters.status
        && this.filters.status.value.length !== 2;
      return isMachineFilter || isTypeFilter || isStatusFilter;
    },
  },
  methods: {
    ...mapMutations('maintenancePlanning', [
      'toggleDrawer',
      'setView',
      'setCalendarFocus',
    ]),
    setToday() {
      this.setCalendarFocus(this.today);
    },
    setPrev() {
      this.calendarRef.prev();
    },
    setNext() {
      this.calendarRef.next();
    },
  },
};
</script>
