<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        small
        v-on="on"
        v-bind="attrs"
        color="error"
        @click="deletePlan"
      >
        <v-icon v-text="'$delete'"></v-icon>
      </v-btn>
    </template>
    {{ $t('maintenancePlan.deleteSparepart') }}
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DeleteSparepart',
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('maintenancePlanning', ['deleteSparepartById']),
    async deletePlan() {
      if (await this.$root.$confirm.open(
        this.$t('maintenancePlan.confirmDeletePartTitle'),
        this.$t('maintenancePlan.confirmDeletePartSubtitle', { name: this.name }),
      )) {
        await this.deleteSparepartById(this.id);
      }
    },
  },
};
</script>
