<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        :small="small"
        v-bind="attrs"
        :color="enabled ? 'warning' : 'success'"
        @click="toggleStar"
      >
        <v-icon v-if="enabled">mdi-sync-off</v-icon>
        <v-icon v-else>mdi-sync</v-icon>
      </v-btn>
    </template>
    <span v-if="enabled">
      {{ $t('maintenancePlan.disable') }}
    </span>
    <span v-else>
      {{ $t('maintenancePlan.enable') }}
    </span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TogglePlan',
  props: {
    planId: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: 'all',
    },
  },
  methods: {
    ...mapActions('maintenancePlanning', ['updatePlanByPlanId']),
    async toggleStar() {
      const status = this.enabled ? 'unable' : 'enable';
      await this.updatePlanByPlanId({
        planId: this.planId,
        payload: { status },
        listType: this.listType,
      });
    },
  },
};
</script>
