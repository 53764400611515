<template>
  <v-data-table
    :items="items"
    :headers="headers"
    hide-default-footer
    disable-pagination
    item-key="_id"
  >
    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <plan-status-template :status="item.status" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.planid="{ item }">
      <plan-id-template
        :planid="item.planid"
        :planName="item.name"
      />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.rule="{ item }">
      <span v-if="item.cronname">
        {{ item.cronname }}
      </span>
      <span v-if="item.duration">
        {{ item.duration }}
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.createdtime="{ item }">
      <plan-start-template :datetime="item.createdtime" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.lasttrigger="{ item }">
      <plan-start-template
        :datetime="item.lasttrigger"
        v-if="item.lasttrigger"
      />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.action="{ item }">
      <plan-actions-template :plan="item" />
    </template>
  </v-data-table>
</template>

<script>
import PlanStatusTemplate from './PlanStatusTemplate.vue';
import PlanIdTemplate from './PlanIdTemplate.vue';
import PlanStartTemplate from './PlanStartTemplate.vue';
import PlanActionsTemplate from './PlanActionsTemplate.vue';

export default {
  name: 'StatusView',
  components: {
    PlanStatusTemplate,
    PlanIdTemplate,
    PlanStartTemplate,
    PlanActionsTemplate,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: '', value: 'status' },
        {
          text: this.$t('maintenancePlan.plan'),
          value: 'planid',
          width: '15%',
        },
        { text: this.$t('maintenancePlan.machine'), value: 'machinename' },
        { text: this.$t('maintenancePlan.type'), value: 'type' },
        {
          text: this.$t('maintenancePlan.rule'),
          value: 'rule',
          width: '10%',
        },
        { text: this.$t('maintenancePlan.checklist'), value: 'solutionname' },
        { text: this.$t('maintenancePlan.lastTriggered'), value: 'lasttrigger' },
        { text: this.$t('maintenancePlan.createdAt'), value: 'createdtime' },
        { text: this.$t('maintenancePlan.createdBy'), value: 'createdby' },
        {
          text: this.$t('maintenancePlan.action'),
          value: 'action',
          sortable: false,
          width: '15%',
        },
      ],
    };
  },
};
</script>
