<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6">
        <plan-info />
      </v-col>
      <v-col cols="12" lg="6">
        <sparepart-list />
      </v-col>
      <v-col cols="12">
        <task-list />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlanInfo from './details/PlanInfo.vue';
import SparepartList from './details/SparepartList.vue';
import TaskList from './details/TaskList.vue';

export default {
  name: 'PlanDetails',
  components: {
    PlanInfo,
    SparepartList,
    TaskList,
  },
};
</script>
